import React from 'react';
import {
  OurBenefitGlobal,
  OurBenefitLimits,
  OurBenefitManagement,
} from '../OurBenefitsIcons';
import ReadMore from '../ReadMore';

export default function OurBenefits() {
  return (
    <section className="container section-m-b our-benefits">
      <div className="row">
        <div className="col-xs-12 col-sm-11 col-md-11 col-md-offset-1">
          <h2 className="h4 narrow">
            {/* Our Knights of Columbus Global Belief ETF offers investors a number
            of potential benefits. */}
            Potential Benefits for Investors:
            <br />
            Knights of Columbus Global Belief ETF
          </h2>
          <div className="our-benefits__list">
            <ul>
              <li className="our-benefits__benefit">
                <div className="our-benefits__benefit__card">
                  <h3 className="our-benefits__benefit__title h4">
                    Global Reach
                  </h3>
                  <div className="our-benefits__benefit__text">
                    <p>
                      Global exposure to dozens of companies whose business
                      practices align with USCCB guidelines around the world.
                    </p>
                  </div>
                  <div className="our-benefits__benefit__icon">
                    <OurBenefitGlobal />
                  </div>
                </div>
              </li>
              <li className="our-benefits__benefit">
                <div className="our-benefits__benefit__card">
                  <h3 className="our-benefits__benefit__title h4">
                    Experienced Management
                  </h3>
                  <div className="our-benefits__benefit__text">
                    {/* TODO: Improve performance of simple Readmore to avoid layout shift */}
                    <ReadMore
                      options="1"
                      popup={
                        <>
                          <h2 className="h5">Experienced Management</h2>
                          <p>
                            Members and non-members alike now have the
                            opportunity to access the experienced team that has
                            been investing with a Catholic perspective since
                            before the United States Conference of Catholic
                            Bishops (USCCB) published its first guidelines on
                            Catholic socially responsible investments in 1986.
                          </p>
                        </>
                      }
                      popupClassName="read-more-popup"
                    >
                      <p>
                        Knights of Columbus investment expertise was
                        historically available only for affiliated investors.
                      </p>
                      <p style={{ display: 'none' }}>
                        Members and non-members alike now have the opportunity
                        to access the experienced team that has been investing
                        with a Catholic perspective since before the United
                        States Conference of Catholic Bishops (USCCB) published
                        its first guidelines on Catholic socially responsible
                        investments in 1986.
                      </p>
                    </ReadMore>
                  </div>
                  <div className="our-benefits__benefit__icon">
                    <OurBenefitManagement />
                  </div>
                </div>
              </li>
              <li className="our-benefits__benefit">
                <div className="our-benefits__benefit__card">
                  <h3 className="our-benefits__benefit__title h4">No Limits</h3>
                  <div className="our-benefits__benefit__text">
                    <p>
                      Investors can feel empowered to buy as little or as much
                      of the ETF as they want through their brokerage account or
                      by speaking with their financial advisor.
                    </p>
                  </div>
                  <div className="our-benefits__benefit__icon">
                    <OurBenefitLimits />
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}
