import React from 'react';
import Form from '../Form';

export default function Questions() {
  return (
    <section className="container section-m-b questions">
      <div className="questions__bg hide-xs show-md">
        <div className="gradient-bg-circle gradient-bg-circle--blue" />
      </div>
      <div className="row">
        <div className="col-xs-12 col-sm-11 col-md-11 col-md-offset-1">
          <h2>Have More Questions?</h2>
          <div className="questions__row">
            <div className="questions__col">
              <div className="questions__text">
                <p>
                  If you'd like additional information or have any questions,
                  please feel free to submit a contact form and we will get back
                  to you as soon as possible.
                </p>
              </div>
            </div>
            <div className="questions__col">
              <div className="questions__form">
                <Form />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
