import React from 'react';
import {
  BrokerETrade,
  BrokerFidelity,
  BrokerRobinhood,
  BrokerSchwab,
  BrokerSoFi,
  BrokerTD,
} from '../BrokerIcons';
// import Form from '../Form';
import { HowToAdvisor, HowToBrokerage } from '../HowToIcons';

function trackConversion() {
  const CMNCTPID = '92820211110T044457453BB80FF5B44EB4DF9A312721C3D2C8B9A';
  const CMNCAMOUNT = '0';
  try {
    if (typeof CMNTrackScriptRan === 'undefined') {
      var CMNTrackScriptRan = 0;
    }
    if (CMNTrackScriptRan <= 1) {
      CMNTrackScriptRan += 1;
      (function (d, s, id) {
        let js;
        const fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {
          return;
        }
        js = d.createElement(s);
        js.id = id;
        js.src = `https://secureaddisplay.com/ctp/js/?CTPID=${CMNCTPID}&ConversionValue=${CMNCAMOUNT}&cachebust=${new Date().getTime()}`;
        fjs.parentNode.insertBefore(js, fjs);
      })(document, 'script', 'cadnet-trackp-js');
    }
  } catch (err) {}
}

export default function HowTo() {
  const handleBrokerageLinkClick = () => {
    if (typeof window.lintrk !== 'undefined') {
      window.lintrk('track', { conversion_id: 5646212 });
      console.log(
        'Triggered',
        `window.lintrk('track', { conversion_id: 5646212 })`
      );
    }
    trackConversion();
  };
  return (
    <section id="invest" className="container section-m-b--sm how-to">
      <div className="how-to__bg hide-xs show-md">
        <div className="gradient-bg-circle" />
      </div>
      <div className="row">
        <div className="col-xs-12 col-sm-11 col-md-11 col-md-offset-1">
          <h2>How to Invest?</h2>
          <div className="how-to__list">
            <ul>
              <li className="how-to__item how-to__item--brokerage">
                <div className="how-to__item__card">
                  <div className="how-to__item__header">
                    <div className="how-to__item__header__text">
                      <h3>Brokerage Accounts</h3>
                      <div className="how-to__item__header__icon hide-sm">
                        <HowToBrokerage id="1" />
                      </div>
                      <p>
                        Online through your brokerage account with access to
                        U.S. stock exchanges.
                      </p>
                    </div>
                    <div className="how-to__item__header__icon hide-xs show-sm">
                      <HowToBrokerage />
                    </div>
                  </div>
                  <div className="how-to__item__list">
                    <ul>
                      <li>
                        <a
                          href="https://robinhood.com/stocks/KOCG"
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={handleBrokerageLinkClick}
                        >
                          <div className="how-to__item__list__logo">
                            <BrokerRobinhood />
                          </div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            viewBox="0 0 11 9"
                            width="11"
                            height="9"
                            className="icon"
                          >
                            <path d="M4.7802 0V1.35952H7.53034L0 8.04154L1.08014 9L8.61048 2.31798V4.75831H10.1426V0H4.7802Z" />
                          </svg>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://screener.fidelity.com/ftgw/etf/goto/snapshot/snapshot.jhtml?symbols=KOCG"
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={handleBrokerageLinkClick}
                        >
                          <div className="how-to__item__list__logo">
                            <BrokerFidelity />
                          </div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            viewBox="0 0 11 9"
                            width="11"
                            height="9"
                            className="icon"
                          >
                            <path d="M4.7802 0V1.35952H7.53034L0 8.04154L1.08014 9L8.61048 2.31798V4.75831H10.1426V0H4.7802Z" />
                          </svg>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://client.schwab.com/Login/SignOn/CustomerCenterLogin.aspx?"
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={handleBrokerageLinkClick}
                        >
                          <div className="how-to__item__list__logo">
                            <BrokerSchwab />
                          </div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            viewBox="0 0 11 9"
                            width="11"
                            height="9"
                            className="icon"
                          >
                            <path d="M4.7802 0V1.35952H7.53034L0 8.04154L1.08014 9L8.61048 2.31798V4.75831H10.1426V0H4.7802Z" />
                          </svg>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://research.tdameritrade.com/grid/public/etfs/profile/profile.asp?symbol=KOCG"
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={handleBrokerageLinkClick}
                        >
                          <div className="how-to__item__list__logo">
                            <BrokerTD />
                          </div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            viewBox="0 0 11 9"
                            width="11"
                            height="9"
                            className="icon"
                          >
                            <path d="M4.7802 0V1.35952H7.53034L0 8.04154L1.08014 9L8.61048 2.31798V4.75831H10.1426V0H4.7802Z" />
                          </svg>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.etrade.wallst.com/v1/stocks/snapshot/snapshot.asp?symbol=KOCG&rsO=new"
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={handleBrokerageLinkClick}
                        >
                          <div className="how-to__item__list__logo">
                            <BrokerETrade />
                          </div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            viewBox="0 0 11 9"
                            width="11"
                            height="9"
                            className="icon"
                          >
                            <path d="M4.7802 0V1.35952H7.53034L0 8.04154L1.08014 9L8.61048 2.31798V4.75831H10.1426V0H4.7802Z" />
                          </svg>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.sofi.com/login/"
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={handleBrokerageLinkClick}
                        >
                          <div className="how-to__item__list__logo">
                            <BrokerSoFi />
                          </div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            viewBox="0 0 11 9"
                            width="11"
                            height="9"
                            className="icon"
                          >
                            <path d="M4.7802 0V1.35952H7.53034L0 8.04154L1.08014 9L8.61048 2.31798V4.75831H10.1426V0H4.7802Z" />
                          </svg>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="how-to__item__disclaimer">
                    <p className="small">
                      Faith Investor Services is not affiliated with these
                      financial service firms. Their listing should not be
                      viewed as a recommendation or endorsement. By clicking the
                      buttons above you are leaving this website and going to a
                      3rd party site. Faith Investor Services is not responsible
                      for content on 3rd party sites.
                    </p>
                  </div>
                </div>
              </li>
              <li className="how-to__item how-to__item--advisor">
                <div className="how-to__item__card">
                  <div className="how-to__item__header">
                    <div className="how-to__item__header__text">
                      <h3>Financial Advisor</h3>
                      <div className="how-to__item__header__icon hide-sm">
                        <HowToAdvisor id="1" />
                      </div>
                      <p>
                        Speak with a Financial Advisor to explore how KOCG may
                        fit with your investing objectives
                      </p>
                    </div>
                    <div className="how-to__item__header__icon hide-xs show-sm">
                      <HowToAdvisor />
                    </div>
                  </div>
                </div>
                <div className="how-to__item__svg">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 394 156"
                    className="hide-xs show-md"
                  >
                    <g fill="#1E75ED" opacity=".1">
                      <path d="M60.7709 46.9119L181.342 155.313H0C0 155.313 5.3483 91.4965 60.7709 46.9119zM71.0596 39.3858L189.994 146.319V0C189.994 0 118.693 2.90034 71.0596 39.3858zM332.596 46.9119L212.025 155.313H393.365C393.365 155.313 388.017 91.4965 332.596 46.9119zM322.302 39.3858L203.369 146.319V0C203.369 0 274.67 2.90034 322.302 39.3858z" />
                    </g>
                  </svg>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}
