import { Link } from 'gatsby';
import React, { useContext } from 'react';
import { MdPlayArrow } from 'react-icons/md';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import LogoKofC from '../../LogoKofC';
import { ModalContext } from '../../ModalContext';

interface Props {
  title: string | React.ReactChild;
}

export default function HeroText({ title }: Props) {
  const { setModal } = useContext(ModalContext);

  return (
    <div>
      <h1 className="hero__title">{title}</h1>
      <p className="hero__text">
        <strong>Faith Investor Services</strong> has partnered with the{' '}
        <strong>Knights of Columbus</strong> to deliver a unique ETF that seeks
        to allow investors to align their portfolios with their religious
        values, while aiming to grow capital over the long-term.
      </p>
      <div className="hero__footer">
        <div className="hero__footer__logo">
          <LogoKofC />
        </div>
        <div className="hero__footer__button-overview">
          <button
            type="button"
            className="button-icon"
            onClick={() =>
              setModal({
                modalOpen: true,
                modalContent: {
                  youtube: 'UINmmWKgtxI',
                },
              })
            }
          >
            <div className="button-icon__icon">
              <MdPlayArrow />
            </div>
            Overview
          </button>
        </div>
        <div className="hero__footer__button-cta">
          <AnchorLink to="#invest" className="button ">
            Invest Now
          </AnchorLink>
        </div>
      </div>
    </div>
  );
}
