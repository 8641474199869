import { StaticImage } from 'gatsby-plugin-image';
import React, { useContext } from 'react';
import { MdPlayArrow } from 'react-icons/md';
import LogoKofC from '../LogoKofC';
import { ModalContext } from '../ModalContext';

export default function Quote() {
  const { setModal } = useContext(ModalContext);

  return (
    <section className="container section-m-b quote">
      <div className="row">
        <div className="col-xs-12">
          <div className="quote__box">
            <div className="quote__box__bg hide-xs show-sm" />
            <div className="quote__text">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 66 66"
                width="66"
                height="66"
                className="quote__icon"
              >
                <path d="M8.2001 65.8C4.8001 60.3334 2.5001 54.6667 1.3001 48.8001C0.166764 42.9334 0.100098 37.1667 1.1001 31.5C2.1001 25.7667 4.16676 20.2667 7.3001 15C10.5001 9.73338 14.7668 5.00005 20.1001 0.800049L28.7001 6.00005C29.5001 6.46671 30.0334 7.00004 30.3001 7.60004C30.6334 8.20004 30.7668 8.83338 30.7001 9.50005C30.7001 10.1 30.5668 10.7 30.3001 11.3C30.0334 11.9 29.7001 12.4 29.3001 12.8C28.0334 14.2667 26.7334 16.3334 25.4001 19C24.1334 21.6 23.2001 24.6 22.6001 28C22.0668 31.3334 22.1001 34.9667 22.7001 38.9C23.3001 42.8334 24.8668 46.8 27.4001 50.8C28.7334 52.8667 29.1001 54.7001 28.5001 56.3C27.9001 57.8334 26.6668 58.9001 24.8001 59.5L8.2001 65.8ZM42.6001 65.8C39.2001 60.3334 36.9001 54.6667 35.7001 48.8001C34.5668 42.9334 34.5001 37.1667 35.5001 31.5C36.5001 25.7667 38.5668 20.2667 41.7001 15C44.9001 9.73338 49.1668 5.00005 54.5001 0.800049L63.1001 6.00005C63.9001 6.46671 64.4334 7.00004 64.7001 7.60004C65.0334 8.20004 65.1668 8.83338 65.1001 9.50005C65.1001 10.1 64.9668 10.7 64.7001 11.3C64.4334 11.9 64.1001 12.4 63.7001 12.8C62.4334 14.2667 61.1334 16.3334 59.8001 19C58.5334 21.6 57.6001 24.6 57.0001 28C56.4668 31.3334 56.5001 34.9667 57.1001 38.9C57.7001 42.8334 59.2668 46.8 61.8001 50.8C63.1334 52.8667 63.5001 54.7001 62.9001 56.3C62.3001 57.8334 61.0668 58.9001 59.2001 59.5L42.6001 65.8Z" />
              </svg>
              <h2 className="h4">
                We are proud to partner with Faith Investor Services (FIS) to
                offer Knights of Columbus members and the broader Catholic
                community an accessible investment vehicle that provides the
                opportunity to build long-term wealth while supporting companies
                whose business practices align with our religious beliefs and
                traditional values.
              </h2>
              <div className="quote__footer">
                <div className="quote__footer__logo">
                  <LogoKofC />
                </div>
                <div className="quote__footer__author">
                  <h3 className="h5 quote__footer__author__name">
                    Patrick Kelly
                  </h3>
                  <p className="quote__footer__author__title">Supreme Knight</p>
                </div>
              </div>
            </div>
            <div className="quote__img">
              <div className="quote__img__inner">
                <StaticImage
                  src="../../images/patrick-kelly.png"
                  alt="Patrick Kelly"
                  objectFit="contain"
                  placeholder="none"
                />
              </div>
              <div className="quote__video-button">
                <button
                  type="button"
                  onClick={() =>
                    setModal({
                      modalOpen: true,
                      modalContent: {
                        youtube: 'zzv8h5RiuOs',
                      },
                    })
                  }
                >
                  <div className="button-icon">
                    <div className="button-icon__icon">
                      <MdPlayArrow />
                    </div>
                    Overview
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
