import React, { useContext, useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { usStates } from '../data/usStates';
import Checkbox from './Checkbox';
import FormSubmitButton from './FormSubmitButton';
import Radio from './Radio';
import Script from './Script';
import { SnackbarContext } from './SnackbarContext';

declare global {
  interface Window {
    lintrk: any;
  }
}

interface FormMessageTypes {
  status: string;
  message: string;
  open: boolean;
}

interface Inputs {
  [key: string]: string | boolean | undefined;
  name: string;
  state: string;
  email: string;
  experience: string;
  isKofCMember: string;
  consent: boolean;
  country?: string;
}

export default function Form() {
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    control,
  } = useForm();

  const [formMessage, setFormMessage] = useState<FormMessageTypes>({
    status: 'success',
    message: '',
    open: false,
  });

  // Set or Unset form message
  function handleResponse(response: Response) {
    console.log(response);
    if (response.ok) {
      setFormMessage({
        status: 'success',
        message: `Thank you. Your message has been sent. We will respond to your message as soon as possible.`,
        open: true,
      });
      reset(); // Clear form on success
    } else {
      setFormMessage({
        status: 'error',
        message: `Sorry, there's an error in sending your message. Please try again later.`,
        open: true,
      });
    }
  }

  // Netlify Forms Functions

  // Encode data

  // IF the form does NOT contain File upload
  function encode(data: Inputs) {
    return Object.keys(data)
      .map(
        (key) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(data[key] || '')}`
      )
      .join('&');
  }

  // IF the form contains File upload
  // function encode(data: Inputs) {
  //   const formData = new FormData();
  //   Object.keys(data).forEach((key) => {
  //     if (data[key] instanceof FileList) {
  //       for (const file of data[key]) {
  //         formData.append(key, file, file.name);
  //       }
  //     } else {
  //       formData.append(key, data[key]);
  //     }
  //   });
  //   return formData;
  // }

  const onSubmit: SubmitHandler<Inputs> = (data, e) => {
    // console.log(data);
    setLoading(true);

    if (typeof window.lintrk !== 'undefined') {
      window.lintrk('track', { conversion_id: 4685940 });
      console.log(`window.lintrk('track', { conversion_id: 4685940 });`);
    }

    const formData = encode({
      'form-name': e?.target.getAttribute('name'),
      ...data,
    });

    fetch('/', {
      method: 'POST',
      // IF form contains file uploads, you should NOT use application/x-www-form-urlencoded -- commment this out
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: formData,
    })
      .then((response) => handleResponse(response))
      .catch((error) => console.error(error))
      .then(() => setLoading(false));
  };

  const { addSnackbar, removeSnackbar } = useContext(SnackbarContext);

  useEffect(() => {
    if (formMessage.open) {
      if (formMessage.status === 'error') {
        addSnackbar(formMessage.message, formMessage.status);
      }
    } else {
      removeSnackbar();
    }
  }, [formMessage, addSnackbar, removeSnackbar]);

  // Thank You Message
  if (formMessage.open && formMessage.status === 'success') {
    return (
      <>
        <Script
          code={`var CMNCTPID='92820211110T044457453BB80FF5B44EB4DF9A312721C3D2C8B9A';
          var CMNCAMOUNT = '0';
          try {
            if (typeof CMNTrackScriptRan === 'undefined') {
              var CMNTrackScriptRan=0;
            } 
            if (CMNTrackScriptRan<=1) {
              CMNTrackScriptRan+=1;
                (function (d, s, id) {
                  var js, fjs = d.getElementsByTagName(s)[0];
                  if (d.getElementById(id)) { return; }
                  js = d.createElement(s); js.id = id;
                  js.src = "https://secureaddisplay.com/ctp/js/?CTPID="+ CMNCTPID +"&ConversionValue=" + CMNCAMOUNT + "&cachebust=" + new Date().getTime();
                  fjs.parentNode.insertBefore(js, fjs);
                } (document, 'script', 'cadnet-trackp-js'));
            }
          } catch (err) {}`}
        />
        <h3 className="h4">Thank You</h3>
        <p>
          Your message has been sent. We will respond to your message as soon as
          possible.
        </p>
      </>
    );
  }

  return (
    <>
      <div className="wrapper">
        <form
          name="contact"
          method="POST"
          data-netlify="true"
          netlify-honeypot="country"
          onSubmit={handleSubmit(onSubmit)}
          aria-busy={!!loading}
        >
          <div className="form-fields">
            <div className="form-field form-field--half">
              <label htmlFor="name">
                <input
                  type="text"
                  id="name"
                  placeholder="Name"
                  {...register('name', { required: true })}
                  aria-invalid={!!errors.name}
                />
                <span className="visually-hidden">Name</span>
              </label>
            </div>
            <div className="form-field form-field--half">
              <label htmlFor="email">
                <input
                  type="email"
                  id="email"
                  placeholder="Email"
                  {...register('email', {
                    required: true,
                    pattern: /^\S+@\S+\.\S+$/,
                  })}
                  aria-invalid={!!errors.email}
                />
                <span className="visually-hidden">Email</span>
              </label>
            </div>
            <div className="form-field form-field--state">
              {/* Using htmlFor for some reason causes Netlify to not recognize the "State" label, which makes it messy */}
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label>
                <span className="visually-hidden">State</span>
                <div className="select-wrapper">
                  <select
                    // id="state"
                    {...register('state', { required: true })}
                    aria-invalid={!!errors.state}
                  >
                    <option value="">State</option>
                    {usStates.map((state) => (
                      <option key={state.name} value={state.name}>
                        {state.name}
                      </option>
                    ))}
                  </select>
                </div>
              </label>
            </div>
            <div className="form-field form-field--experience">
              <Controller
                render={({ field: { onChange, value } }) => (
                  <fieldset
                    className="radio-group"
                    aria-invalid={!!errors.experience}
                  >
                    <legend className="radio-group__label visually-hidden">
                      Your Experience as Investor
                    </legend>
                    <div className="radio-group__radio">
                      <Radio
                        id="individual-investor"
                        name="experience"
                        value="Individual Investor"
                        currentValue={value}
                        onChange={onChange}
                        label="Individual Investor"
                      />
                      <Radio
                        id="financial-professional"
                        name="experience"
                        value="Financial Professional"
                        currentValue={value}
                        onChange={onChange}
                        label="Financial Professional"
                      />
                    </div>
                  </fieldset>
                )}
                control={control}
                name="experience"
                rules={{ required: true }}
              />
            </div>
            <div className="form-field">
              <Controller
                render={({ field: { onChange, value } }) => (
                  <fieldset
                    className="radio-group"
                    aria-invalid={!!errors.isKofCMember}
                  >
                    <legend className="radio-group__label">
                      Are you a Knights of Columbus Member?
                    </legend>
                    <div className="radio-group__radio">
                      <Radio
                        id="yes"
                        name="isKofCMember"
                        value="yes"
                        currentValue={value}
                        onChange={onChange}
                        label="Yes"
                      />
                      <Radio
                        id="no"
                        name="isKofCMember"
                        value="no"
                        currentValue={value}
                        onChange={onChange}
                        label="No"
                      />
                    </div>
                  </fieldset>
                )}
                control={control}
                name="isKofCMember"
                rules={{ required: true }}
              />
            </div>
            <div className="form-field">
              <label htmlFor="message">
                <textarea
                  id="message"
                  placeholder="Message"
                  {...register('message', { required: true })}
                  aria-invalid={!!errors.message}
                />
                <span className="visually-hidden">Message</span>
              </label>
            </div>
            <div className="form-field">
              <Controller
                render={({ field: { onChange, value } }) => (
                  <Checkbox
                    id="consent"
                    name="consent"
                    value={value}
                    onChange={onChange}
                    invalid={!!errors.consent}
                    label={
                      <small>
                        I consent to the terms of the{' '}
                        <a
                          href="https://faithinvestorservices.com/wp-content/uploads/2021/07/FIS-Knights-of-Columbus-Global-Belief-ETF-Prospectus.pdf"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Privacy Policy
                        </a>
                        .
                      </small>
                    }
                  />
                )}
                control={control}
                name="consent"
                rules={{ required: true }}
              />
            </div>
            <div style={{ display: 'none' }}>
              <label htmlFor="country">
                Don’t fill this out if you’re human:{' '}
                <input
                  type="text"
                  autoComplete="false"
                  tabIndex={-1}
                  {...register('country')}
                />
              </label>
            </div>
            <div className="form-field form-field--submit">
              <FormSubmitButton loading={loading} />
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
