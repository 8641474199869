import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import {
  BenefitDiversified,
  BenefitFees,
  BenefitStocks,
  BenefitTax,
} from '../BenefitsIcons';

export default function Value() {
  return (
    <section className="container section-m-b value">
      <div className="value__bg hide-xs show-md">
        <div className="gradient-bg-circle gradient-bg-circle--blue" />
      </div>
      <div className="value__bg-2 hide-xs show-md">
        <div className="gradient-bg-circle" />
      </div>
      <div className="row reverse-sm">
        <div className="col-xs-12 col-sm-5 col-sm-offset-1">
          <div className="value__box">
            <h2 className="h3">
              Our ETF seeks to help align investment portfolios with Catholic
              religious beliefs, while aiming to grow capital over the long
              term.
            </h2>
          </div>
        </div>
        <div className="col-xs-12 col-sm-6 col-md-5 col-md-offset-1">
          <div className="value__text">
            <h2>The Value of ETFs</h2>
            <p>
              ETFs are publicly traded investment funds that hold a diversified
              portfolio of securities, packaged into one investment product that
              trades on a stock exchange. As such, ETFs are accessible
              investment vehicles for both financial professionals and
              individual investors.
            </p>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 col-sm-10 col-md-5 col-md-offset-1">
          <div className="value__popularity">
            <h3>Growing Popularity of ETFs</h3>
            <p>
              Over the past 15 years, the adoption of ETFs has consistently
              grown due to the potential benefits they offer investors.
            </p>
            <iframe
              title="Assets Invested in US ETFs and ETPs Industry"
              aria-label="Grouped Column Chart"
              src="//datawrapper.dwcdn.net/XvK5T/1/"
              scrolling="no"
              frameBorder="0"
              style={{ border: 'none' }}
              width="652"
              height="347"
            />
          </div>
        </div>
        <div className="col-xs-12 col-sm-8 col-md-5 col-md-offset-1">
          <div className="value__benefits">
            <h3>Potential Benefits of ETFs</h3>
            <ul>
              <li className="value__benefit">
                <div className="value__benefit__icon">
                  <BenefitStocks />
                </div>
                <div className="value__benefit__text">
                  <h4 className="value__benefit__title">Like Stocks</h4>
                  <p>ETFs trade throughout the day just like stocks do.</p>
                </div>
              </li>
              <li className="value__benefit">
                <div className="value__benefit__icon">
                  <BenefitFees />
                </div>
                <div className="value__benefit__text">
                  <h4 className="value__benefit__title">Lower Fees</h4>
                  <p>
                    On average, ETFs have lower management fees than mutual
                    funds.
                  </p>
                </div>
              </li>
              <li className="value__benefit">
                <div className="value__benefit__icon">
                  <BenefitTax />
                </div>
                <div className="value__benefit__text">
                  <h4 className="value__benefit__title">Tax</h4>
                  <p>Tax efficiency.</p>
                </div>
              </li>
              <li className="value__benefit">
                <div className="value__benefit__icon">
                  <BenefitDiversified />
                </div>
                <div className="value__benefit__text">
                  <h4 className="value__benefit__title">Diversified</h4>
                  <p>
                    ETFs offer access to a diversified basket of securities
                    packaged into one investable product.
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}
