import React, { useContext, useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { useWindowSize } from '../hooks/useWindowSize';
import { ModalContext } from './ModalContext';

// TODO: Handle edge cases where readmore option = 0, or children is only child (not array)

interface Props {
  children: any;
  options?: any;
  popup?: React.ReactNode | true;
  popupClassName?: string;
}

export default function ReadMore({
  children,
  options,
  popup,
  popupClassName,
}: Props) {
  const { width } = useWindowSize();
  const [maxLength, setMaxLength] = useState<number | null>(null);

  const sortedOptions = Array.isArray(options)
    ? options.sort((a, b) =>
        !a.breakpoint || a.breakpoint <= b.breakpoint ? -1 : 1
      )
    : [{ maxLength: options }];

  useEffect(() => {
    if (sortedOptions) {
      Object.values(sortedOptions).some((bp, i) => {
        const currentBreakpoint = bp.breakpoint || 0;
        if (
          !sortedOptions[i + 1] ||
          (width &&
            width >= currentBreakpoint &&
            width < sortedOptions[i + 1].breakpoint)
        ) {
          setMaxLength(bp.maxLength || null);
          return true;
        }
        return false;
      });
    }
  }, [width, maxLength]);

  // Popup
  const { setModal } = useContext(ModalContext);

  if (!maxLength) {
    return children;
  }

  const Visible = () =>
    React.Children.map(children, (child, i) => {
      const count = i + 1;

      if (count <= maxLength) {
        return child;
      }
    });

  const Hidden = () => {
    const [expanded, setExpanded] = useState(false);
    const variants = {
      expanded: {
        height: 'auto',
        opacity: 1,
        marginTop: '1.5rem',
        marginBottom: '1.5rem',
      },
      collapsed: {
        height: 0,
        opacity: 0,
      },
    };

    if (children.length > maxLength) {
      if (popup) {
        const HiddenChildren = () =>
          React.Children.map(children, (child, i) => {
            const count = i + 1;
            if (count > maxLength) {
              return child;
            }
          });
        const popupChildren =
          popup === true ? (
            <div className="card">
              <div className="inner">
                <HiddenChildren />
              </div>
            </div>
          ) : (
            popup
          );
        return (
          <>
            <div style={{ display: 'none ' }}>
              <HiddenChildren />
            </div>
            <div className="read-more-button-wrapper">
              <button
                type="button"
                className="read-more"
                onClick={() =>
                  setModal({
                    modalOpen: true,
                    children: popupChildren,
                    wrapperClassName: popupClassName,
                  })
                }
              >
                Read More
                <span className="plus-minus" />
              </button>
            </div>
          </>
        );
      }
      return (
        <>
          <motion.div
            style={{ overflow: 'hidden' }}
            initial="collapsed"
            animate={expanded ? 'expanded' : 'collapsed'}
            variants={variants}
            transition={{ duration: 0.4, ease: [0.04, 0.62, 0.23, 0.98] }}
          >
            {React.Children.map(children, (child, i) => {
              const count = i + 1;

              if (count > maxLength) {
                return child;
              }
            })}
          </motion.div>
          <div className="read-more-button-wrapper end-xs">
            <button
              type="button"
              className="read-more"
              onClick={() => setExpanded(!expanded)}
            >
              {expanded ? 'View Less' : 'Read More'}
              <span
                className={`plus-minus ${expanded ? 'plus-minus--minus' : ''}`}
              />
            </button>
          </div>
        </>
      );
    }

    return null;
  };

  return (
    <>
      <div className="read-more-container">
        <Visible />
        <Hidden />
      </div>
    </>
  );
}
