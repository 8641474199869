import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

export default function Themes() {
  return (
    <section className="container section-m-b themes">
      <div className="themes__bg hide-xs show-md">
        <div className="gradient-bg-circle gradient-bg-circle--blue" />
      </div>
      <div className="themes__bg-2 hide-xs show-md">
        <div className="gradient-bg-circle" />
      </div>
      <div className="row">
        <div className="col-xs-12 col-md-9 col-md-offset-2 col-lg-7 col-lg-offset-2">
          <div className="themes__inner">
            <h2 className="themes__title">
              <span className="p">
                The FIS Knights of Columbus Global Belief ETF (KOCG)
              </span>{' '}
              strictly follows the investment policies of the USCCB{' '}
              <span className="p">
                (United States Conference of Catholic Bishops).
              </span>
            </h2>
            <div className="themes__img">
              <StaticImage
                src="../../images/bishop-crown.png"
                alt="USCCB"
                placeholder="tracedSVG"
              />
            </div>
            <div className="themes__details">
              <h3 className="h4">
                There are six major themes in these guidelines:
              </h3>
              <div className="themes__list">
                <ol>
                  <li>Respect for life</li>
                  <li>Promote human dignity</li>
                  <li>Catholic teachings on war and peace</li>
                  <li>Pursue economic justice</li>
                  <li>Protect the environment</li>
                  <li>Encourage corporate responsibility</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
